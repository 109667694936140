.PaginationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ItemList {
        width: 100%;
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .ElementPaginationList {
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
        border-radius: 10px;
    }

    .ElementPaginationList {


         img {
             width: 100%;
             height: 100%;
             object-fit: contain;
         }
    }
}

.PaginationPageList {
    display: flex;
    align-items: center;
    justify-content: center;

    .page-item {
        width: 24px;
        height: 22px;
        cursor: pointer;
        margin: 0 10px;
        padding-top: 3px;
        padding-right: 1px;

        &.active {
            background: #FF2A00;
            border-radius: 5px;

            a {
                color: #fff;
            }
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            color: #878787;
        }
    }
}