.ContainerLoadImage {
    width: 24px;
    height: 24px;
    margin-left: 9px;
    cursor: pointer;
}

.inputLoadImage {
    width: 24px;
    height: 24px;
    position: relative;
    opacity: 0;
    z-index: 2;
    cursor: pointer
}