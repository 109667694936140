.ContainerPreview {
    position: relative;
}

.stickyBlockPreview {
    display: flex;
    width: 100%;
    max-width: max-content
}
.TextPreview {
    background: #262626;
    margin-right: 20px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.buttonDownload {
    background: #262626;
    width: 70px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;

    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
}

.RerenderElement {
    background: #262626;
    width: max-content;
    padding: 0 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    margin-left: 15px;

    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
}