.EditTextItem {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item {
        margin-bottom: 15px;
        margin-right: 8px;

        //&__title {
        //    color: #878787;
        //    font-size: 14px;
        //    line-height: 17px;
        //    margin-bottom: 5px;
        //}
    }

    .TextSize {
        width: 160px;
    }

    .colorText {
        width: 110px;
        height: 30px;
    }

    .fontFamily {
        width: 100%;

        .SelectFontFamily {
            width: 100%;
            max-width: 280px;
        }
    }

    .fontStyle {
        width: 100%;
        max-width: 280px;

        .listFontType {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__item {
                width: 40px ;
                height: 40px;
                margin-right: 40px;
                border-radius: 10px;
                border: 1px solid #363738;
                padding: 0 5px;

                &.active {
                    border-color: #FF2A00;
                }

                &:last-child {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .textPosition {
        width: 100%;
        max-width: 260px;

        .EditText_PositionTextSideImage {
            display: block;

            &__Active {
                display: none;
            }
        }

        .textPositionList {
            display: flex;
            justify-content: flex-start;

            &__item {
                width: 35px;
                height: 35px;
                margin-right: 15px;

                &.active {

                    .EditText_PositionTextSideImage {
                        display: none;

                        &__Active {
                            display: block;
                        }
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .textPositionList__item {
        }

    }
}

.ContainerTextPosition {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .positionTextItem {
        width: 28px;
        height: 28px;
        position: relative;
        margin-right: 24px;

        &.active {
            .activeTextPosition {
                display: block !important;
            }

            .positionText {
                display: none;
            }
        }

        .positionText {
            width: 28px;
            height: 28px;
            //position: relative;
            margin-right: 24px;
            object-fit: contain;
            position:absolute;
            left: 0;
            top: 0;
        }

        //&:first-child::after,
        //&:nth-child(2)::after,
        //&:last-child::after {
        //    content: '';
        //    position: absolute;
        //    width: 100%;
        //    height: 100%;
        //    background: url("./images/leftSide.png") no-repeat center;
        //    background-size: contain;
        //    top: 0;
        //    left: 0;
        //    z-index: 0;
        //}

        //&:nth-child(2)::after {
        //    background: url("./images/center.png") no-repeat center;
        //    background-size: contain;
        //}
        //
        //&:last-child::after {
        //    background: url("./images/rightSide.png") no-repeat center;
        //    background-size: contain;
        //}

        &:last-child {
            margin-right: 0;
        }

        .activeTextPosition {
            display: none;
        }

        input {
            display: inline-block;
            width: 100%;
            height: 100%;
            margin: 0;
            opacity: 0;
            position: relative;
            z-index: 2;
        }
    }
}