.MyInput {
    width: 100%;
    max-width: 280px;
    background: #262626;
    border: 2px solid #363738;
    color: #E2E2E2;
    font-size: 15px;
    line-height: 17px;
    padding: 12px 10px 12px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    outline: none;
}

.MyInput::placeholder {
    color: #363738;
}
