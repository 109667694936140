.sectorsCount {

    .countBoxesContainer {
        margin-top: 20px;
        display: flex;
        width: 100%;
        max-width: 300px;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    .countBoxesItem {
        display: flex;
        align-items: center;

        .countSectionTitle {
            font-size: 18px;
            font-weight: bold;
            color: #878787;
            margin-right: 6px;
        }

        .radioCheckCountSection {

        }
    }
}

.WheelStyle {
    margin-bottom: 20px;
}

.sectorsEdit {

    .listEditSectors {
        margin-top: 15px;
    }

    .itemEdit {

    }

    .countSectorTitle {
        color: #E2E2E2;
        margin-bottom: 10px;
    }
}

.EditColorContainer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .ColorPeaker {
        width: 100%;
        max-width: 100px
    }

    p {
        margin-right: 15px;
    }

    & > div {
        width: 100px;
    }
}
