.selectBlock {
    margin-bottom: 15px;
}

.littleTitle {
    margin-bottom: 15px;
}
.ItemList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

//.topicLandPagination {
//    display: flex;
//    flex-direction: column;
//    justify-content: center;
//    align-items: center;
//}