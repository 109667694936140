
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0; 
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

*, *:after, *:before {
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: 100%
}

.container.land_8 {
  background: url("../images/bg.png") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 10px 0;
}

.land_8 .container__logo {
padding: 0 10px;
    width: 100%;
    max-width: 100%;
    display: flex;
    height: 100px;
    margin-bottom: 10px;
    justify-content: center;
}
.land_8 .img_logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.land_8 .container__text {
    width: 100%;
    margin-bottom: 15px;
}

.land_8 .top_text {
  color: #D9B4FF;
  text-align: center;
  text-shadow: 0 5px 3px #2F0030;
  font-family: Skia;
  font-size: 28px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  padding: 0 10px;
  width: 100%;
}

.land_8 .container__wheel {
    width: 100%;
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
}

.land_8 .container__wheel::before {
  content: ' ';
  background-image: url(../images/wheel-vector.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 28px;
  z-index: 1;
}

.land_8 .container__wheel::after {
  content: ' ';
  background-image: url(../images/hero.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: -60px;
  z-index: 3;
}

.land_8 .border {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.land_8 .img_border {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.land_8 .middle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.land_8 .wheel_container {
    position: absolute;
    width: 100%;
    max-width: 290px;
    height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.land_8 .img_middle {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all ease 4s;
}

.land_8 .container__btn {
  width: 270px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url(../images/btn.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.land_8 .btn_text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  z-index: 3;

  color: #D9B4FF;
  text-shadow: 0 5px 3px #2F0030;
  font-family: Skia;
  font-size: 40px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.land_8 .popup {
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.50);
  position: absolute;
  top: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.land_8 .popup__container {
  width: 80%;
  max-width: 400px;
  height: 50%;
  background-image: url(../images/popup.png);
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
}

.land_8 .popup__title {
  width: 250px;
  margin-bottom: 10px;
}

.land_8 .popup_title {
  color: #FFF;
  text-align: center;
  text-shadow: 0 5px 3px #2F0030;
  font-family: Skia;
  font-size: 39px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.land_8 .popup__content {
  width: 250px;
  margin-bottom: 15px;
}

.land_8 .popup_content {
  color: #D9B4FF;
  text-align: center;
  text-shadow: 0 5px 3px #2F0030;
  font-family: Skia;
  font-size: 19px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.land_8 .popup__btn {
  max-width: 85%;
  height: 70px;
  background-image: url(../images/popup_btn.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 30px 5px;
  cursor: pointer;
}

.land_8 .popup__btn__link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.land_8 .popup_btn_text {
  color: #FFF;
  text-shadow: 0 1px 3px #2F0030;
  font-family: Skia;
  font-size: 33px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-transform: uppercase;
}

.land_8 .wheelTextContainer {
  position: absolute;
  width: 290px;
  height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.land_8 .wheelTextList {
  width: 290px;
  height: 290px;
  transition: all ease 4s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.land_8 .text__section {
  position: absolute;
  width: max-content;
  text-align: center;
  transform-origin: center;
}
