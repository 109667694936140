.formLoadFileLand {

    .loadFIle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 27px;

        .form_block {
            display: flex;
            align-items: center;
            position: relative;
            width: 75px;

            &:after {
                content: '';
                position: absolute;
                width: 24px;
                height: 24px;
                background: url("./images/load-file.png") no-repeat center;
                background-size: contain;
                top: 0;
                right: 0;
                cursor: pointer;
                z-index: 0;
            }

            .labelToInput {
                font-size: 16px;
                line-height: 19px;
                color: #E2E2E2;
            }
        }

        .inputLoad {
            width: 24px;
            height: 24px;
            position: relative;
            opacity: 0;
            z-index: 2;
        }
    }
}

.editLogo {
    width: 100%;

    &__title {
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        margin-bottom: 10px;
    }

    .sizeLogoBlock {
        display: flex;
        justify-content: space-between;

        &__item {
            margin-bottom: 36px;
        }

        &__itemTitle {
            color: #878787;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 5px;
        }

        &__input {
            display: inline-block;
            width: 100%;
            max-width: 132px;
            font-size: 16px;
            line-height: 19px;
            color: #e2e2e2;
            padding: 12px 10px 12px 15px;
            outline: none;
            border: 2px solid #363738;
            background: #262626;
            border-radius: 10px;
        }
    }

    .titleSizeBlock {
        font-size: 14px;
        line-height: 16px;
        color: #878787;
        margin-bottom: 14px;
    }

    .positionLogoContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .positionLogoItem {
        width: 28px;
        height: 28px;
        position: relative;
        margin-right: 24px;

        &.active {
            .activeImage {
                display: block !important;
            }

            .positionImage {
                display: none;
            }
        }

        .positionImage {
            width: 28px;
            height: 28px;
            //position: relative;
            margin-right: 24px;
            object-fit: contain;
            position:absolute;
            left: 0;
            top: 0;
        }

        //&:first-child::after,
        //&:nth-child(2)::after,
        //&:last-child::after {
        //    content: '';
        //    position: absolute;
        //    width: 100%;
        //    height: 100%;
        //    background: url("./images/leftSide.png") no-repeat center;
        //    background-size: contain;
        //    top: 0;
        //    left: 0;
        //    z-index: 0;
        //}

        //&:nth-child(2)::after {
        //    background: url("./images/center.png") no-repeat center;
        //    background-size: contain;
        //}
        //
        //&:last-child::after {
        //    background: url("./images/rightSide.png") no-repeat center;
        //    background-size: contain;
        //}

        &:last-child {
            margin-right: 0;
        }

        .activeImage {
            display: none;
        }

        input {
            display: inline-block;
            width: 100%;
            height: 100%;
            margin: 0;
            opacity: 0;
            position: relative;
            z-index: 2;
        }
    }

    .positionLogoItem__left {

    }

    .positionLogoItem__center {

    }

    .positionLogoItem__right {

    }

}
