.inputText {
    margin-bottom: 15px;
}

.CheckedTracker {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;

    .ItemTrackerCheck {
        display: flex;
        align-content: center;
        margin-right: 20px;
        justify-content: center;

        label {
            height: 15px;
            font-size: 15px;
        }
    }
}

.NameLand {
    margin-bottom: 15px;
}