.MyHeader {
    padding: 20px 0;
    background: #262626;
}

.MyHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.MyHeader_logo {
    width: 190px;
    height: 19px;
}

.MyHeader_logoLink{
    display: inline-block;
    width: 100%;
    height: 100%;
}

.MyHeader_logoImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.LangList_item {
    color: #E2E2E2;
    font-size: 15px;
    line-height: 17px;
    font-family: BreitsBold;
}