.typeLand__item {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;

    &.active {
        border: 2px solid #FF2A00;
        border-radius: 5px;
    }

    .titleSlots {
        margin-bottom: 10px;
    }

    .image_container {
        width: 280px;
        height: 130px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}