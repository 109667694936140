@font-face {
    font-family: 'BreitsBold';
    src: local('MyFoBreitsnt'), url('../font/HalvarBreit-Bd.ttf') format('truetype');
}
@font-face {
    font-family: 'BreitsLt';
    src: local('BreitsLt'), url('../font/HalvarBreit-Lt.ttf') format('truetype');
}
@font-face {
    font-family: 'HBreitsMd';
    src: local('HBreitsMd'), url('../font/HalvarBreit-Md.ttf') format('truetype');
}
@import "PaginationStyle";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: HBreitsMd !important;
    color: #fff;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
    background: #1F1F1F;
}

*, *:after, *:before {
    box-sizing: border-box;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1 {
    color: red;
    font-size: 20px;
    font-family: Arial
}
.css-1dimb5e-singleValue {
    color: #878787 !important;
}
.css-tr4s17-option {
    background: #FF2A00 !important;
}
.css-d7l1ni-option {
    background: gray!important;
}

.container {
    padding:0 20px;
    width: 1440px;
    height: 100%;
    margin: 0 auto;
}

.showContentEdit {
    width: 100%;
    max-width: 380px;
    padding-top: 30px;
    padding-left: 25px;
}

.createLandSide {
    max-width: 800px;
    background:#262626;
}

.btn-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ContainInput {
    width: 100%;
    max-width: 250px;
}

.labelInput {
    font-size: 18px;
    line-height: 22px;
    padding-left: 15px;
    margin-bottom: 10px;
}

.MainContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.createLandSide {
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: flex-start;
}


.selectBlock {
    width: 100%;
    max-width: 280px;

    .selectList {
        width: 100%;
        background: #515154;

        option {
            font-size: 14px;
            line-height: 16px;
            color: #E2E2E2;
            outline: none;
        }
    }
}

.title-block {
    font-size: 14px;
    color: #878787;
    line-height: 16px;
    margin-bottom: 10px;
}

.littleTitleSlogan {
    color: #878787;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
}



