.ElementPaginationList {
    width: 100%;
    max-width: 50%;
    height: 250px;
    margin-bottom: 15px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.ItemList {
    display: flex;
    flex-wrap: wrap;
}
.PaginationPageList {
    margin: 0 auto;
}