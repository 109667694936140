.ContainerError {
    width: 100%;
    max-width: 500px;
    padding: 15px 10px;
    border: 2px solid #FF2A00;
    border-radius: 15px;
    background: #262626;

    position: fixed;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 10px);

    .TitleError {
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #FF2A00;
    }

    .ErrorBody {
        color: #FF2A00;
    }
}