.navBarElement {
    display: flex;
    flex-direction: column;
    padding-right: 120px;
    background: #242426;

    .listLink {
        width: 100%;
        max-width: 306px;
    }

    .listLinkItem {
        width: 100%;
        height: 69px;

        a {
            &.listLinkItem {
                background: red;
            }

            &.active {
                background: #515154;
                width: calc(100% + 15px);
                border-right: 4px solid #FF2A00;
            }
        }
    }

    .listLinkItem > a:has(.active) .listLinkItem > a {
        background: red;
        display: inline-block;
        color: black;
    }

    a {
        text-decoration: none;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        padding-left: 80px;

        &.active {
            background: #262626;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
}
